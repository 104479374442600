<template>
    <div>
        11111
        <button @click="goToBaidu">跳转到百度</button>  
        <a href="https://www.baidu.com">打开示例链接</a>

        <button @click="goToBaidu1">自己</button>  
        <a href="https://charge.wdzsapp.com/">自己</a>
    </div>
   
    
</template>
<script>
import {
    setToken,getToken
} from '../util/auth'
import payFooter from "@/components/common/footerPay";
import { Checkbox, Toast } from 'vant';
import request from "..//plugin/request";
import wx from 'weixin-js-sdk'
     export default {
      components: {
   
    payFooter
  },
            name:'applyText',
             data(){
                  return {
                    order_id:"",
                    jiage:"",
                    shijian:"",
                    value:"",
                    mingzi:"",
                    dianhua:"",
                    erwei:"",
                    token:"",
                    data6:"",
                    buy:0,
                    data9:""
                 }
          },
 computed: {
   
  },
  mounted(){
  },
  beforeCreate(){
    // document.title = "课程详情"
  },
     created(){

    // document.title = "课程详情"

  },
  methods:{
    goToBaidu() {
    window.location.href = "https://www.baidu.com";
  },
  goToBaidu1(){
    window.location.href = "https://charge.wdzsapp.com/";
  }
  

  }
}
</script>
<style scoped>
.bottom_xin{
  position: fixed;
  bottom: 0px;
}
.yuandian{
  width: 3px;
height: 3px;

background: #B1B9F8;
border-radius: 212px 212px 212px 212px;
position: absolute;
left: 60px;
top: 445px;
}
.yuandian2{
  width: 3px;
height: 3px;

background: #B1B9F8;
border-radius: 212px 212px 212px 212px;
position: absolute;
left: 60px;
top: 490px;
}
.mingshi{
  width: 266px;
  height: 19px;
  position: absolute;
left: 54px;
top: 405px;
}
.erweitu{
  width: 95px;
height: 95px;
/* background: #F5F5F5; */
border-radius: 6px 6px 6px 6px;
position: absolute;
left: 49px;
top: 410px;
}
.dianh{
  font-weight: 400;
font-size: 14px;
color: #3D3D3D;
position: absolute;
left: 80px;
top: 480px;

}
.miaos{
  width: 240px;
  font-weight: 400;
font-size: 14px;
color: #3D3D3D;
position: absolute;
left: 80px;
top: 435px;
}
.top_text2{
  font-weight: 500;
font-size: 16px;
color: #5D6382 ;
position: absolute;
text-align: center;
left: 122px;
top: 104px;
}
.top{
  position: relative;
}
.top_text{
  font-weight: 700;
  font-size: 24px;
  color: #352A8A;
  text-align: center;
  margin-top: 29px;
  width: 275px;
  height: 60px;
  position: absolute;
  left: 50px;
}
.bottom_kuai{
  width: 375px;
  height: 64px;
  background: #3947B4 ;
  margin-top: -15px;
}
.bg_img{
    width: 375.65px;
    height: 564.94px;
}
.bottom_left{
    height: 36px;
    font-weight: 400;
font-size: 20px;
color: #AEAEAE;
display: flex;
line-height: 36px;
margin-left: 15px;
margin-top: 14px;
}
.price_font{
    font-weight: 400;
font-size: 26px;
color: #3D3D3D;
line-height: 36px;
}
.bao_button{
    width: 137px;
height: 48px;
background: #3074FF;
border-radius: 10px 10px 10px 10px;
font-weight: 400;
font-size: 16px;
color: #FFFFFF;
line-height: 48px;
margin-top: -42px;
    text-align: center;
    margin-left: 223px;
}
.bao_button2{
    width: 137px;
height: 48px;
/* background: #3074FF; */
background: gray;
border-radius: 10px 10px 10px 10px;
font-weight: 400;
font-size: 16px;
color: #FFFFFF;
line-height: 48px;
margin-top: -42px;
    text-align: center;
    margin-left: 223px;
}
</style>
